<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Vendor List
        <v-spacer></v-spacer>
         <v-btn
            class="mr-4"
            color="secondary"
            @click="sendInvitation"
            :loading="loading"
            :disabled="loading"
        >Send Invitation</v-btn>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          v-debounce:300ms="getItems"
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-options': [5,10,20,30,50,100,-1],
        }"
        :loading="loading || isProcessingVendorLogin"
        item-key="vendor_id"
        class="elevation-1 custom-table"
        show-select
        v-model="formData.selected"
      >
        <template v-slot:item.action="{item}">
          <div class="d-flex" style="gap: 5px; flex-wrap: wrap;">
          <v-btn
            color="grey darken-2"
            icon
            dark
            title="Add Representative"
            @click="rep_dialog = true;getAttendeeList(item.vendor_id);rep_data.selected_vendor = item.vendor_id"
            x-small
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
          <v-btn x-small color="grey darken-2" icon dark :to="'/vendor/resource/'+item.vendor_id">
            <v-icon>mdi-file-document-multiple-outline</v-icon>
          </v-btn>
          <v-btn x-small color="grey darken-2" icon dark :to="'/vendor/details/'+item.vendor_id">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
          <v-btn x-small color="grey darken-2" icon dark :to="'/vendor/edit/'+item.vendor_id">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn x-small color="grey darken-2" icon dark @click="confirmDelete(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn x-small color="grey darken-2" outlined @click="loginIntoVendorAccount(item.vendor_id)">
            login
            <v-icon size="15" class="ml-1">mdi-open-in-new</v-icon>
          </v-btn>
          </div>
        </template>
         <template v-slot:item.last_sent_invitation="{item}">
          {{getDateTime(item.last_sent_invitation)}}
        </template>
        <template v-slot:item.last_logged_in="{item}">
          {{getDateTime(item.last_logged_in)}}
        </template>
      </v-data-table>
    </v-card>
    <div style="position: fixed;bottom: 15px;left:50%;" class="d-flex">
    <v-btn color="primary" fab bottom right to="/vendor/add" small class="mr-5">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn color="secondary"
    fab bottom
    title="Import"
    @click="import_dialog = true"
    small
    >
      <v-icon>mdi-upload</v-icon>
    </v-btn>
    </div>
    <!-- Select Representative-->
    <v-dialog v-model="rep_dialog" max-width="320">
      <v-card>
        <v-card-title>Select Representative</v-card-title>
        <v-card-text class="pb-0">
          <v-autocomplete
            v-model="rep_data.selected_rep"
            :items="representative_list"
            :error-messages="form_error.selected_rep"
            label="Select Representative"
            item-text="full_name"
            item-value="attendee_id"
            outlined
            dense
            multiple
            :loading="isLoadingRepresentatives"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="rep_dialog = false;rep_data.selected_vendor = null ;rep_data.selected_rep =[];form_error.selected_rep=null"
          >Close</v-btn>
          <v-btn color="secondary" text style="background: rgba(0,150,0,0.1)" @click="assignRepresentative(vendor)">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">
          Are you sure delete
          <b>{{vendor.name}}</b>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-4" text @click="deleteItem(vendor)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Import Vendors dialog-->
    <v-dialog v-model="import_dialog" persistent max-width="290">
      <v-card :loading="import_loading">
        <v-card-title>Import Vendors</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-file-input
              label="Select file"
              hint="CSV file"
              v-model="import_data.file"
              persistent-hint
              autofocus
              accept=".csv"
              :error-messages="import_error.file"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-btn x-small color="blue" text target="_blank" :href="FRONT_ASSETS + 'vendor_import_example.csv'" block>Click here to download sample file</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="red lighten-2" dark @click="import_dialog = false">close</v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="importVendors">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Import Vendors dialog end-->
  </v-container>
</template>
<script>

import { parseParams, getAlphabets } from "../../plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "Vendorlist",
  data() {
    return {
      loading: false,
      rep_dialog: false,
      dialog: false,
      import_dialog : false,
      import_loading : false,
      form_error: {},
      representative_list: [],
      formData: {
        selected: []
      },
      rep_data: {
        selected_rep: [],
        selected_vendor: null,
      },
      vendor: {
        name: null,
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 50,
        data: [],
      },
      headers: [
        { text: "Name", value: "name",class: "custom-span"},
        { text: "Phone No.", value: "phone_number" },
        { text: "Company Name", value: "company_name" },
        { text: "Email", value: "email" },
        { text: "Sponsorship", value: "sponsortype.name" },
        { text: "Last Sent.", value: "last_sent_invitation"},
        { text: "Last Login.", value: "last_logged_in"},
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 50,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
      import_data : {
        file : null
      },
      import_error : {},
      isProcessingVendorLogin: false,
      isLoadingRepresentatives: false,
      vendorPanelBaseUrl: process.env.VUE_APP_VENDOR_PANEL_BASE_URL
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
    import_dialog : function(val){
      if(!val){
        this.import_error = {}
        this.import_data.file = null
      }
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios.get("/admin/vendor/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.vendor = item;
    },
    deleteItem(item) {
      const index = this.items.data.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post("/admin/vendor/delete", {
        vendor_id: item.vendor_id,
      })
        .then((res) => {
          if (res.status) {
            _self.items.data.splice(index, 1);
            _self.dialog = false;
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    getAttendeeList(vendorId) {
      this.loading = true;
      this.isLoadingRepresentatives = true;

      this.$axios
        .get(
          `/admin/attendee/list?pagination=false&sponsor_type=10&populateRepresentative=true&vendor_id=${vendorId}`
        )
        .then((res) => {
          const resData = res.data;

          if (resData.status) {
            this.representative_list = resData.data;
            this.rep_data.selected_rep = resData.data
              .filter((item) => !!item.rep_data)
              .map((item) => item.attendee_id); 
          } else {
            this.setAlert({
              show: true,
              variant: "danger",
              message: "Something went wrong",
              dismissCountDown: 3000,
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {
          this.isLoadingRepresentatives = false;
        });
    },
    assignRepresentative() {
      let _self = this;
      this.$axios.post(`admin/assign-representative`, _self.rep_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.rep_dialog = false;
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.rep_data.selected_rep = [];
            _self.rep_data.selected_vendor = null;
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getDateTime(date_time) {
      if(date_time != '' && date_time != null) {
        let invitation_date = this.moment(date_time);
        return invitation_date.format('MM/DD hh:mm a');
      } else {
        return ''
      }
    },
     sendInvitation() {
      let _self = this;
      if(_self.formData.selected.length > 0) {
        this.loading = "secondary";
        let form_ata = new FormData();
        for (let key in _self.formData.selected) {
          form_ata.append(`vendor_ids[${key}]`, _self.formData.selected[key].vendor_id);
        }
        this.$axios.post(`admin/vendor/send_invitation`, form_ata)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.formData.selected = []
            _self.getItems()
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
      } else {
        _self.setAlert({
          show: true,
          variant: "danger",
          message: 'Please select at least one vendor to send invitation',
          dismissCountDown: 5000,
        });
      }
    },
    importVendors(){
      this.import_loading = true
      let formData = new FormData();
      formData.append('file',this.import_data.file)
      this.$axios.post('/admin/vendor/import_vendors',formData).
      then((response) => {
        let response_data = response.data;
        if (response_data.status) {
           this.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.import_dialog = false
            this.getItems();
        } else {
          let errors = response_data.error;
           for (let key in errors) {
            this.import_error[errors[key].field] = errors[key].message;
          }
        }
         this.import_loading = false
      })
    },
    loginIntoVendorAccount(vendorId) {
      if (!vendorId) {
        console.log("VENDOR ID NOT FOUND");
        return;
      }

      this.isProcessingVendorLogin = true;

      this.$axios.post(`/admin/vendor/${vendorId}/login_code`)
        .then((res) => {
          let resData = res.data;

          if (resData.status) {
            window.open(`${this.vendorPanelBaseUrl}login?code=${resData.data}`, '_blank');
          } else {
            let errorMsg = "Something went wrong";

            if (resData.error && Array.isArray(resData.error)) {
              errorMsg = resData.error[0].message;
            }

            this.setAlert({
              show: true,
              variant: "danger",
              message: errorMsg,
              dismissCountDown: 4000,
            });
          }
        })
        .catch(() => {
          let errorMsg = "Something went wrong";
          
          this.setAlert({
            show: true,
            variant: "danger",
            message: errorMsg,
            dismissCountDown: 4000,
          });
        })
        .finally(() => {
          this.isProcessingVendorLogin = false;
        })
    }
  }
};
</script>
<style>
 .custom-table .text-start{
   font-size: 12px !important;
 }
 .custom-table th,.custom-table td {
   padding: 0 8px !important;
 }
</style>